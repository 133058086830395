
import React, { useState, useEffect } from 'react';


export default function Header({ className }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);


    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const doNothing = () => {

    }

    const handleClickOutside = (event) => {
        // Close menu if clicking outside of menu or hamburger
        if (!event.target.closest('.menu') && !event.target.closest('.hamburger')) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        // Cleanup event listener on component unmount or isOpen state change
        return () => document.removeEventListener('click', handleClickOutside);
    }, [isOpen]);

    useEffect(() => {
        const handleScroll = () => {
            // Check if the user has scrolled from the top
            setIsScrolled(window.scrollY > 0);
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    });

    return (
        <header className="site-header">
            <div className={`beforeHeader ${isScrolled ? 'moved' : ''}`}>
                <div className="container">
                    <div className="contact-info">
                        <div className="info">
                            <a href={doNothing()}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" aria-hidden="true">
                                    {/* Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free */}
                                    <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                                </svg>
                                <span>Chicago, Illinois</span>
                            </a>
                        </div>
                        <div className="info">
                            <a href="tel:+16189124543">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-hidden="true">
                                    {/* Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free */}
                                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                                </svg>
                                <span>618 912 4543</span>
                            </a>
                        </div>
                        <div className="info">
                            <a href={doNothing()}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-hidden="true">
                                    <path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                                </svg>
                                <span>Office Hours: 9:00 AM – 4:00 PM</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${className} ${isScrolled ? 'moved' : ''}`}>
                <div className="container">
                    <a href="/"><img
                        src="assets/images/logo-white.png"
                        alt=""
                        className="logo"
                    /></a>
                    <nav className={`menu ${isOpen ? 'opened' : ''}`}>
                        <ul>
                            <li><a href="/#calendly-booking" onClick={handleToggle}>Book Free Consultation</a></li>
                            <li><a href="/#pricing" onClick={handleToggle}>Pricing</a></li>
                            <li><a href="/#calendly-booking" className='open-calendly-form' onClick={handleToggle}>Contact</a></li>
                        </ul>
                    </nav>
                    <div className={`hamburger ${isOpen ? 'open' : ''}`}
                        onClick={handleToggle}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </header>
    )
}