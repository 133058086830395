
import React, { useState, useEffect } from 'react';
import './../styles/main.scss';
import Header from './../pages/parts/Header';
import Footer from './../pages/parts/Footer';
import { Helmet } from 'react-helmet';


function Home() {



  useEffect(() => {
    // Load the Calendly CSS and JS dynamically
    const loadCalendlyScript = () => {
      const link = document.createElement('link');
      link.href = 'https://assets.calendly.com/assets/external/widget.css';
      link.rel = 'stylesheet';
      document.head.appendChild(link);

      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      script.onload = () => {
        // Initialize the Calendly widget after the script loads
        if (window.Calendly) {
          window.Calendly.initBadgeWidget({
            url: 'https://calendly.com/webdevelopersahil/30min',
            text: 'Book Free Consultation',
            color: '#007bff',
            textColor: '#ffffff'
          });

          // Add the click event listener when the Calendly script has loaded
          const btns = document.querySelectorAll('.open-calendly-form');
          btns.forEach((btn) => {
            btn.addEventListener('click', function () {
              window.Calendly.initPopupWidget({ url: 'https://calendly.com/webdevelopersahil/30min' });
            });
          });
        }
      };
      document.body.appendChild(script);
    };

    // Call the function to load the script
    loadCalendlyScript();
   

    // Clean up by removing the script and link when the component is unmounted
    return () => {
      const scriptTag = document.querySelector('script[src="https://assets.calendly.com/assets/external/widget.js"]');
      const linkTag = document.querySelector('link[href="https://assets.calendly.com/assets/external/widget.css"]');
      if (scriptTag) scriptTag.remove();
      if (linkTag) linkTag.remove();
    }; 
  }, []);


  return (
    <>
      <Helmet>
        <title>Digime - Digital Solutions for Growing Businesses</title>
      </Helmet>
      <Header className="mainHeader" />
      <div className="hero-bg-image">
        <main className="site-main">
          <div className="container">
            <div className="grid-2">
              <div className="hero-section">
                <h1>Get Online, Stress Free</h1>
                <p className="subheading">Making Websites Simple for Local Heroes Like You</p>
                <a href="#pricing" className="white-btn">Get Started Now</a>
              </div>
            </div>
          </div>
        </main>

      </div>

      <section className="grow-your-business">
        <div className="container">
          <div className="grid-2">
            <img
              src="assets/images/grow-your-business.jpg"
              alt=""
              className="logo"
            />
            <div className="grow-biz">
              <h2>Grow Your Business Online</h2>
              <p>We create websites that work for you, attracting new clients and increasing your bottom line. We handle everything technical - hosting, domain, and maintenance - so you can focus on running your business.</p>
              <a href="#pricing" className="primary-btn">Get Started & Grow Today!</a>
            </div>
          </div>
        </div>
      </section>

      <section className="what-we-deal-with">
        <div className="container">
          <h2>We deal with the aspects of<br /> professional IT services</h2>
          <div className="grid-2">
            <div className="grid-item">
              <div className="icon">
                <img src="assets/images/icons/code.png" alt="Modern Web Development" />
              </div>
              <div className="info">
                <h3>Modern Web Development</h3>
                <p>Attract new customers with a website that looks great and works flawlessly on all devices. We use latest technologies to build your website.</p>
              </div>
            </div>
            <div className="grid-item">
              <div className="icon">
                <img src="assets/images/icons/custom-development.png" alt="Custom Development" />
              </div>
              <div className="info">
                <h3>Custom Development</h3>
                <p>Need a unique feature like an AI chatbot or social media ad integration? We can build it for you! (Additional charges may apply)</p>
              </div>
            </div>

            <div className="grid-item">
              <div className="icon">

                <img src="assets/images/icons/Hassle-Free-Maintenance.png" alt="Hassle Free Maintenance" />
              </div>
              <div className="info">
                <h3>Hassle-Free Maintenance</h3>
                <p>Relax - hosting, domain, and updates are all taken care of. You focus on your business, we'll handle your website.</p>
              </div>
            </div>
            <div className="grid-item">
              <div className="icon">
                <img src="assets/images/icons/unlimited-changes.png" alt="Unlimited Changes" />
              </div>

              <div className="info">
                <h3>Unlimited Changes</h3>
                <p>Get expert help via email (support@digime.us) or phone (618) 912-4543. Ask for as many changes as you want.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-stats">
        <div className="container">
          <div className="grid-3">
            <div className="stat">
              <strong>9+</strong>
              <span>Years Of Experience</span>
            </div>
            <div className="stat">
              <strong>54+</strong>
              <span>Successfully Projects</span>
            </div>
            <div className="stat">
              <strong>40+</strong>
              <span>Satisfied Happy Clients</span>
            </div>
          </div>
        </div>
      </section>

      <section className="pricing" id="pricing">
        <div className="container">
          <h2>Effective & flexible pricing</h2>
          <div className="grid-3">
            <div className="pricing-plan">
              <h3>Simple & Powerful</h3>
              <p>Businesses who need a professional website with essential features.</p>
              <p className="price"><strong>$99</strong>/month</p>
              <ul className="features">
                <li>Hosting & Domain Included</li>
                <li>Modern & Responsive Design</li>
                <li>Easy Navigation</li>
                <li>Contact Form</li>
                <li>Unlimited Changes</li>
                <li>Free Stock Photos</li>
                <li>Google My Business Integration</li>
                <li>Dedicated Support (via email & phone)</li>
              </ul>
              <a href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-65K21636EM678503AM4ZPKIY" className="white-btn">Select the package</a>
            </div>
            <div className="pricing-plan">
              <h3>Custom Growth</h3>
              <p>Businesses who need a unique website with advanced features.</p>
              <p className="price"><strong>$199</strong>/month</p>
              <ul className="features">
                <li>All Features from Simple & Powerful Plan</li>
                <li>Custom Design & Functionality</li>
                <li>E-commerce Integration (additional charges may apply)</li>
                <li>AI Chatbot integration</li>
              </ul>
              <a href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-37E067235Y0619254M4ZPLCA" className="white-btn active">Select the package</a>
            </div>
            <div className="pricing-plan">
              <h3>Enterprise Plan</h3>
              <p>Do you have a specific vision for your website that goes beyond our pre-built plans? Our Enterprise Plan allows you to build a website entirely tailored to your needs. From advanced features to specific functionalities, we'll work with you to create a website that sets you apart.</p>
              <a href="javascript:;" className="white-btn open-calendly-form">Contact Us</a>
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </>
  );
}

export default Home;
