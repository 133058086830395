// src/pages/PrivacyPolicy.js
import React from 'react';

import Header from './../pages/parts/Header';
import Footer from './../pages/parts/Footer';

import { Helmet } from 'react-helmet';
const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Digime - Privacy Policy</title>
      </Helmet>

      <Header className="page-header mainHeader" />
      <div class="container">
        <article style={{ padding: '20px' }}>
          <header>
            <h1>Privacy Policy</h1>
            <p><strong>Effective Date:</strong> 12 November, 2024.</p>
          </header>

          <section>
            <h2>1. Information We Collect</h2>
            <p>We collect the following personal and business information:</p>
            <ul>
              <li><strong>Personal Information:</strong> Name, email address, phone number</li>
              <li><strong>Website Details:</strong> Domain and hosting details (if applicable)</li>
              <li><strong>Business Information:</strong> Address, and other relevant data to tailor web solutions for your business</li>
            </ul>
          </section>

          <section>
            <h2>2. How We Use Your Information</h2>
            <p>The information we collect is used to help your business grow and enhance your website's performance:</p>
            <ul>
              <li><strong>Customer Growth:</strong> To support your business in gaining more customers</li>
              <li><strong>Marketing Services:</strong> We may conduct marketing campaigns on your behalf and track analytics to optimize results</li>
            </ul>
          </section>

          <section>
            <h2>3. Sharing Your Information</h2>
            <p>We share your information with trusted third-party partners only to run marketing campaigns and advertisements. We will seek your <strong>consent</strong> before any data sharing for these purposes.</p>
          </section>

          <section>
            <h2>4. Data Security</h2>
            <p>We take data security seriously and use the latest technologies and best practices to ensure your data is safe. Our websites are equipped with <strong>SSL certificates</strong> for secure communication.</p>
          </section>

          <section>
            <h2>5. Cookies and Tracking Technologies</h2>
            <p>We use <strong>cookies</strong> for the following purposes:</p>
            <ul>
              <li><strong>Analytics:</strong> To track site performance and improve user experience</li>
              <li><strong>Personalization:</strong> To enhance your site experience based on your preferences</li>
            </ul>
          </section>

          <section>
            <h2>6. Your Rights</h2>
            <p>You have the right to request the deletion of your personal data. Please contact us at <a href="mailto:support@digime.us">support@digime.us</a> for any data-related requests.</p>
          </section>

          <section>
            <h2>7. Children's Privacy</h2>
            <p>Our website is not intended for children. We do not knowingly collect personal information from children under 13.</p>
          </section>

          <section>
            <h2>8. Data Retention</h2>
            <p>We retain your data as long as you are an active user of our services. If you do not log in after your subscription ends, we will delete your data within <strong>60 days</strong>.</p>
          </section>

          <section>
            <h2>9. Contact Us</h2>
            <p>If you have any questions or concerns about our privacy practices, please reach out to us at <a href="mailto:support@digime.us">support@digime.us</a>.</p>
          </section>

          <section>
            <h2>10. Policy Updates</h2>
            <p>We may update this Privacy Policy from time to time. Any updates will be posted on this page, so please review it periodically.</p>
          </section>
        </article>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
